import React, { useEffect } from 'react'
import styled from 'styled-components'
import CCIcon from '../../assets/cc.svg'
import BYIcon from '../../assets/by.svg'
import ExtIcon from '../../assets/ext.svg'

const BUILD_VERSION = process.env.BUILD_VERSION || 'localhost'

const StyledLink = styled.a`
    color: inherit;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
`

const StyledFooter = styled.footer`

    ${props => props.theme.embedded && `
        display: none;
    `}
    font-size: 16px;
    color: inherit;
    padding-top: 6em;
    text-align: center;
`


const SubFooter = styled.p`
    font-size: 0.8rem;
    margin-top: 0.5rem;
`

const Footer = () => {
    return (
        <StyledFooter>

            <div>
                    <img src={CCIcon} alt='Creative Commons CC icon'/>
                    <img src={BYIcon} alt='Creative Commons BY icon'/>
                    &nbsp;
                    <StyledLink href="https://ben.website/" target="_blank">Benjamin Goodman</StyledLink> {new Date().getFullYear()}
            </div>

            <p>
                Quotes provided by&nbsp;
                <StyledLink href="https://zenquotes.io/" target="_blank"><span>ZenQuotes API <img src={ExtIcon} alt='External Link Icon' /></span></StyledLink>
            </p>
            <SubFooter>{BUILD_VERSION}</SubFooter>
        </StyledFooter>
    )
}

export default Footer
