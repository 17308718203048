import { deflate, inflate } from 'pako'
import { fromUint8Array, toUint8Array } from 'js-base64'

export const serializeState = (state: string): string => {
    const data = new TextEncoder().encode(state)
    const compressed = deflate(data, { level: 9 })
    return fromUint8Array(compressed, true)
}


export const deserializeState = (state: string): string => {
    const data = toUint8Array(state);
    return inflate(data, { to: 'string' });
}
