import React, { useEffect } from 'react'
import styled from 'styled-components'

import CopyLinkButton from 'src/components/CopyLinkButton'
import NewCryptogramButton from 'src/components/NewCryptogramButton'
import CopyCryptogramButton from 'src/components/CopyCryptogramButton'

const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;


    & > button {
        margin: 0 10px;
        padding: 10px;

        img {
            width: 32px;
            height: 32px;
        }
    }
`

const StyledHeader = styled.header`
    ${props => props.theme.embedded && `
        display: none;
    `}
    padding-bottom: 3em;
`


const Header = () => {
    return (
        <StyledHeader>
            <Row>
                <h1>Cryptograms</h1>
                <NewCryptogramButton />
                <CopyCryptogramButton />
                <CopyLinkButton />
            </Row>
        </StyledHeader>
    )
}

export default Header
