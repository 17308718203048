import React, { useCallback } from 'react'

import CopyIcon from 'assets/copy.svg'
import IconButton from 'src/components/IconButton'
import { deserializeState } from 'src/util/serde'
import { CryptogramRequest } from 'src/useCryptogram'

const CopyCryptogramButton = () => {

    const handleClick = useCallback(() => {
        const cryptogramEncoded = document.cookie.split('; ').find(row => row.startsWith('cryptogram='))?.split('=')[1]
        if (!cryptogramEncoded) {
            return
        }
        //deserialize state and extract cryptogram encoded prop.
        const state = JSON.parse(deserializeState(cryptogramEncoded)) as CryptogramRequest
        const cryptogramEncodedText = state.data?.encoded
        if (!cryptogramEncodedText) {
            console.error('Failed to extract state from ', state)
            return
        }
        navigator.clipboard.writeText(cryptogramEncodedText)
    }, [])

    return (
        <IconButton
            title='Copy Cryptogram Text'
            aria-label='Copy Cryptogram Text'
            onClick={handleClick}
            iconSvg={CopyIcon}
        />
    )
}

export default CopyCryptogramButton
