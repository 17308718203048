import React from 'react'
import styled from "styled-components";

const IconButtonWrapper = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`

export interface IconButtonProps {
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
    iconSvg: string,
    title: string,
    altText?: string,
}

const IconButton = ({
    onClick,
    iconSvg,
    altText,
    title
}: IconButtonProps) => {
    return (
        <IconButtonWrapper onClick={onClick} title={title}>
            <img src={iconSvg} alt={altText || title}/>
        </IconButtonWrapper>
    )
}

export default IconButton
