import React, { useCallback } from 'react'

import ShareIcon from 'assets/share.svg'
import IconButton from 'src/components/IconButton'

const CopyLinkButton = () => {

    const handleClick = useCallback( (_event: React.MouseEvent<HTMLButtonElement>) => {
        const cryptogramEncoded = document.cookie.split('; ').find(row => row.startsWith('cryptogram='))?.split('=')[1]
        if (!cryptogramEncoded) {
            return
        }
        navigator.clipboard.writeText(`${window.location.origin}/hash/${cryptogramEncoded}`)
    }, [ window.location.href ])

    return (
        <IconButton
            title="Share this cryptogram"
            aria-label="Share this cryptogram"
            onClick={handleClick}
            iconSvg={ShareIcon}
        />
    )

}

export default CopyLinkButton
