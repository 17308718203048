import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

import { router } from 'src/views/router'
import { ThemeProvider, createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    color: white;
    background-color: #222222;
    text-transform: uppercase;
    font-size: x-large;
    font-family: "Ubuntu Mono", monospace;
    margin: 10%;
    ${ props => props.theme.embedded && `
      margin: 0;
    `}
  }
`

const theme = (embedded) => ({
  embedded
})

export const App = () => {
  const [embeddedView, setEmbeddedView] = React.useState(false)

  useEffect(() => {
      window.location.hash === '#embed' && setEmbeddedView(true)
  }, [])

    return (
        <ThemeProvider theme={theme(embeddedView)}>
            <GlobalStyle/>
            <RouterProvider router={router}/>
        </ThemeProvider>
    )
}
