import React, { useCallback } from 'react'

import NewIcon from 'assets/new.svg'
import IconButton from 'src/components/IconButton'

const NewCryptogramButton = () => {

    const handleClick = useCallback(() => {
        document.cookie = 'cryptogram=;max-age=0;path=/'
        window.location.href = '/random'
        window.location.reload()
    }, [])

    return (
        <IconButton
            title='New Cryptogram'
            onClick={handleClick}
            iconSvg={NewIcon}
        />
    )
}

export default NewCryptogramButton
