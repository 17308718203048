import React from 'react'
import { Outlet, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import styled from 'styled-components'

import Footer from 'src/components/Footer'
import Header from 'src/components/Header'


const StyledContainer = styled.div`
    margin: 0 auto;
    max-width: 1300px;
`

const Main = () => {
    return <>
        <Header />
        <StyledContainer>
            <Outlet />
        </StyledContainer>
        <Footer />
    </>
}

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/' element={<Main />}>

            <Route index lazy={() => import('./Random')} />
            <Route path='random' lazy={() => import('./Random')} />

            <Route path='hash'>
                <Route path=':state' lazy={() => import('./ResumeState')} />
            </Route>

            <Route
                path="*"
                lazy={() => import('./Error')}
            />
        </Route>
    )
)